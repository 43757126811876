import { useCallback, useState, memo, useMemo, useRef } from 'react'
import { GenericColumnModel, FormatterCell } from '../models'
import styles from './body.module.scss'
import { EditableCell } from './EditableCell/EditableCell'
import { Tooltip } from '../Tooltip/Tooltip'
import { cellFormatter } from './cellFormatter'

const defaultTooltipState = {
	visible: false,
	position: { x: 0, y: 0 }
}

type Props = {
	column: GenericColumnModel
	value: any
	columnWidth: number
	rowData: any
	cellEdited?: (rowData: any, columnId: string, value: any) => Promise<void>
}

const CellWithoutMemo = ({ column, value, columnWidth, rowData, cellEdited }: Props) => {
	const contentRef = useRef<HTMLDivElement>(null);
	const [saving, setSaving] = useState(false);
	const [tooltipState, setTooltipState] = useState(defaultTooltipState);

	const formatterCell: FormatterCell = useMemo(
		() => ({
			value,
			rowData,
			columnId: column.id
		}),
		[value, rowData, column.id]
	)

	const isCellEditable = useMemo(
		() => column.editable?.(formatterCell) || false,
		[column, formatterCell]
	)

	const clickCallback = useCallback(
		(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			if (isCellEditable) {
				// don't select row
				e.stopPropagation();
			}
		},
		[isCellEditable]
	)

	const onChangeCompleteCallback = useCallback(
		async (columnId: string, value: any) => {
			if (cellEdited && rowData[columnId] !== value) {
				setSaving(true);
				await cellEdited(rowData, columnId, value);
				setSaving(false);
			}
		},
		[cellEdited, rowData]
	)

	const showTooltipCallback = useCallback(
		(e: React.MouseEvent) => {
			const content = contentRef.current;
			if (!content) {
				return;
			}
			if (content.scrollWidth > content.clientWidth) {
				setTooltipState({
					visible: true,
					position: { x: e.clientX + 12, y: e.clientY + 12 }
				})
			}
		},
		[]
	)

	const hideTooltipCallback = useCallback(
		() => setTooltipState(defaultTooltipState),
		[]
	)

	const formattedCell = useMemo(
		() => cellFormatter(column, formatterCell, isCellEditable),
		[column, formatterCell, isCellEditable]
	)

	const className = `${styles.cell} ${isCellEditable ? styles.editing : ''} ${!!column.frozen ? styles.frozen : ''}`;

	const styleMemo = useMemo(
		() => (!isCellEditable && column.getStyle) ? column.getStyle(value) : {},
		[value, column, isCellEditable]
	)

	// data-type and data-columnid must always be present, so be aware when changing
	return (
		<div
			className={className}
			style={{ width: `${columnWidth}px`, ...styleMemo }}
			onClick={clickCallback}
			data-type='cell'
			data-body='true'
		>
			{isCellEditable &&
				<EditableCell
					column={column}
					value={value}
					onChangeComplete={onChangeCompleteCallback}
					loading={saving}
					formatterCell={formatterCell}
				/>
			}
			<div
				ref={contentRef}
				className={`${styles.content} ${styles[`align_${column.align}`] || ''}`}
				style={{ visibility: isCellEditable ? 'hidden' : 'unset' }}
				onMouseOver={showTooltipCallback}
				onMouseLeave={hideTooltipCallback}
			>
				<span className={styles.inner} data-columnid={column.id}>
					{formattedCell}
				</span>
				{tooltipState.visible &&
					<Tooltip
						content={formattedCell.toString()}
						topPosition={tooltipState.position.y}
						leftPosition={tooltipState.position.x}
					/>
				}
			</div>
		</div>
	)
}

export const Cell = memo(CellWithoutMemo);
