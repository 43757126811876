import { useCallback, useEffect, useMemo, useState } from 'react';
import { ClockIcon } from 'components/icons/icons';
import { OverlaySpinner } from 'components/Spinner';
import { Input, SelectionType } from '..';
import { ControlsCommonProps } from '../../fields';
import { changeSelection, convertToTicks, convertToTime, decreaseSelection, getInputSelection, getSelectionEnum, increaseSelection, SelectionEnum, selectionOrder12h, selectionOrder24h, TimeValues, TimeValuesString } from './helper';
import styles from './timePicker.module.scss';
import { MeridiemTimeEnum } from 'utils/MeridiemTimeEnum';

export type TimePickerProps = ControlsCommonProps<number | undefined> & {
	size?: 'medium' | 'small'
	focus?: boolean
	loading?: boolean
	isDuration?: boolean
}

const defaultTimeValuesString = { hours: '--', minutes: '--', amPm: '--' };

export const TimePicker = ({ value, onChange, disabled, onBlur, size = 'medium', focus = false, loading, isDuration = false }: TimePickerProps) => {
	const [timeValues, setTimeValues] = useState<TimeValues>({});
	const [timeValuesString, setTimeValuesString] = useState<TimeValuesString>(defaultTimeValuesString);
	const [selectionEnum, setSelectionEnum] = useState<SelectionEnum>();

	const is24h = isDuration; // || hour24 // TODO: proveriti i lokalizaciju
	const selectionOrder = is24h ? selectionOrder24h : selectionOrder12h;

	const inputSelection: SelectionType = useMemo(
		() => getInputSelection(selectionEnum),
		[selectionEnum]
	)

	const onBlurCallback = useCallback(
		() => {
			setSelectionEnum(undefined);
			// if some selection is "change in progress", for example we have 10:1- AM. we want to reset that
			setTimeValuesString(defaultTimeValuesString);
			// if some selection is deleted, for example we have 10:-- AM we want to reset that to value
			const values = convertToTime(value, is24h);
			setTimeValues(values);
			onBlur?.();
		},
		[is24h, onBlur, value]
	)

	const inputValue = useMemo(
		() => {
			let minutes = timeValuesString.minutes;
			if (timeValues.minutes !== undefined) {
				minutes = String(timeValues.minutes).padStart(2, '0');
			}

			let hours = timeValuesString.hours;
			if (timeValues.hours !== undefined) {
				hours = String(timeValues.hours).padStart(2, '0');
			}

			let amPm = is24h ? '' : ` ${timeValuesString.amPm}`;
			if (timeValues.amPm !== undefined) {
				amPm = timeValues.amPm === MeridiemTimeEnum.AM ? ' AM' : ' PM';
			}

			return `${hours}:${minutes}${amPm}`
		},
		[is24h, timeValues.amPm, timeValues.hours, timeValues.minutes, timeValuesString.amPm, timeValuesString.hours, timeValuesString.minutes]
	)

	useEffect(
		() => {
			const values = convertToTime(value, is24h);
			setTimeValues(values);
			setTimeValuesString(defaultTimeValuesString);
		},
		[is24h, value]
	)

	const onChangeCallback = useCallback(
		(newValue: string | undefined) => {
			if (newValue === undefined || newValue === '') {
				onChange?.(undefined);
				return;
			}

			const newTimeValues: TimeValues = { ...timeValues };
			const newTimeValuesString: TimeValuesString = { ...timeValuesString };

			switch (selectionEnum) {
				case SelectionEnum.Hours: {
					const enteredChar = newValue[0];
					const firstChar = timeValuesString.hours[0];

					if (firstChar === '-') {
						newTimeValues.hours = undefined;
						newTimeValuesString.hours = `${enteredChar}-`;
					} else {
						const hours = parseInt(`${firstChar}${enteredChar}`);
						if (isDuration) {
							newTimeValues.hours = Math.min(hours, 99);
						} else if (is24h) {
							newTimeValues.hours = Math.min(hours, 23);
						} else {
							newTimeValues.hours = Math.min(hours, 11);
						}
						newTimeValuesString.hours = '--';
						const newSelection = changeSelection(selectionEnum, selectionOrder, true);
						setSelectionEnum(newSelection);
					}
					break;
				}
				case SelectionEnum.Minutes: {
					const enteredChar = newValue[3];
					const firstChar = timeValuesString.minutes[0];

					if (firstChar === '-') {
						newTimeValues.minutes = undefined;
						newTimeValuesString.minutes = `${enteredChar}-`;
					} else {
						const minutes = parseInt(`${firstChar}${enteredChar}`);
						newTimeValues.minutes = Math.min(minutes, 59);
						newTimeValuesString.minutes = '--';
						const newSelection = changeSelection(selectionEnum, selectionOrder, true);
						setSelectionEnum(newSelection);
					}
					break;
				}
				case SelectionEnum.AmPm: {
					const enteredChar = newValue[6];
					const isPm = enteredChar === 'p' || enteredChar === 'P';
					newTimeValues.amPm = isPm ? MeridiemTimeEnum.PM : MeridiemTimeEnum.AM;

					const newSelection = changeSelection(selectionEnum, selectionOrder, true);
					setSelectionEnum(newSelection);
					break;
				}
			}

			const ticks = convertToTicks(newTimeValues);
			if (ticks !== undefined && ticks !== value) {
				onChange?.(ticks);
			} else {
				setTimeValues(newTimeValues);
				setTimeValuesString(newTimeValuesString);
			}
		},
		[is24h, isDuration, onChange, selectionEnum, selectionOrder, timeValues, timeValuesString, value]
	)

	const handleClickCallback = useCallback(
		(e: React.MouseEvent<HTMLInputElement>) => {
			const newSelectionEnum = getSelectionEnum(e.currentTarget.selectionStart);
			setSelectionEnum((state) => state === undefined ? SelectionEnum.Hours : newSelectionEnum);
			setTimeValuesString(defaultTimeValuesString);
		},
		[]
	)

	// handle keyboard arrows
	const handleKeyDownCallback = useCallback(
		(eventKey: string, e: React.KeyboardEvent<HTMLInputElement>) => {
			switch (eventKey) {
				case 'ArrowUp': {
					e.preventDefault();
					const newValues = increaseSelection(timeValues, selectionEnum, isDuration, is24h);
					const ticks = convertToTicks(newValues);
					if (ticks === undefined) {
						setTimeValues(newValues);
					} else {
						onChange?.(ticks);
					}
					return;
				}
				case 'ArrowDown': {
					e.preventDefault();
					const newValues = decreaseSelection(timeValues, selectionEnum, isDuration, is24h);
					const ticks = convertToTicks(newValues);
					if (ticks === undefined) {
						setTimeValues(newValues);
					} else {
						onChange?.(ticks);
					}
					return;
				}

				case 'ArrowRight': {
					e.preventDefault();
					const newSelection = changeSelection(selectionEnum, selectionOrder, true);
					setSelectionEnum(newSelection);
					setTimeValuesString(defaultTimeValuesString);
					return;
				}
				case 'ArrowLeft': {
					e.preventDefault();
					const newSelection = changeSelection(selectionEnum, selectionOrder, false);
					setSelectionEnum(newSelection);
					setTimeValuesString(defaultTimeValuesString);
					return;
				}

				case 'Delete':
				case 'Backspace': {
					e.preventDefault();
					const { selectionStart, selectionEnd } = e.currentTarget;
					// here we suppose that everything is selected, so we delete value
					if (selectionStart === 0 && selectionEnd && selectionEnd > 2) {
						onChange?.(undefined);
						return;
					}

					switch (selectionEnum) {
						case SelectionEnum.Hours:
							setTimeValues((state) => ({
								...state,
								hours: undefined
							}))
							setTimeValuesString((state) => ({
								...state,
								hours: '--'
							}))
							break;
						case SelectionEnum.Minutes:
							setTimeValues((state) => ({
								...state,
								minutes: undefined
							}))
							setTimeValuesString((state) => ({
								...state,
								minutes: '--'
							}))
							break;
						case SelectionEnum.AmPm:
							setTimeValues((state) => ({
								...state,
								amPm: undefined
							}))
							setTimeValuesString((state) => ({
								...state,
								amPm: '--'
							}))
							break;
					}

					return;
				}

				case '0':
				case '1':
				case '2':
				case '3':
				case '4':
				case '5':
				case '6':
				case '7':
				case '8':
				case '9':
					if (selectionEnum === SelectionEnum.AmPm) {
						e.preventDefault();
						return;
					} else {
						return;
					}
				case 'a':
				case 'A':
					if (selectionEnum === SelectionEnum.AmPm || e.ctrlKey) {
						return;
					} else {
						e.preventDefault();
						return;
					}
				case 'p':
				case 'P':
					if (selectionEnum !== SelectionEnum.AmPm) {
						e.preventDefault();
					}
					return;
				case 'Tab':
					// we want to keep tab to switch focus
					return;
				default:
					e.preventDefault();
			}
		},
		[selectionEnum, timeValues, isDuration, is24h, onChange, selectionOrder]
	)

	const handleKeyUpCallback = useCallback(
		(eventKey: string) => {
			switch (eventKey) {
				case 'Tab': {
					setSelectionEnum(SelectionEnum.Hours);
					return;
				}
			}
		},
		[]
	)

	return (
		<div className={styles.container}>
			<Input
				value={inputValue}
				onChange={onChangeCallback}
				onBlur={onBlurCallback}
				onClick={handleClickCallback}
				onKeyDown={handleKeyDownCallback}
				onKeyUp={handleKeyUpCallback}
				disabled={disabled || loading}
				hideMaxLength
				focus={focus}
				size={size}
				selection={inputSelection}
			/>
			<ClockIcon
				className={styles.icon}
				width={12}
				height={12}
				fill="currentColor"
			/>
			{/* loading */}
			{loading && <OverlaySpinner useBrandColor />}
		</div>
	)
}
