import { memo, useCallback, useMemo } from 'react'
import styles from './footer.module.scss'
import { Cell } from './Cell'
import { FormatterCell, GenericColumnModel } from '../models'
import { ColumnWidthMap } from '../CustomTable'
import { cellFormatter } from '../Body/cellFormatter'

type Props = {
	rowData?: any
	columns: GenericColumnModel[]
	columnWidthMap: ColumnWidthMap
}

const RowWithoutMemo = ({ rowData, columns, columnWidthMap }: Props) => {
	const columnsMemo = useMemo(
		() => columns.filter(column => column.frozen !== 'end'),
		[columns]
	)

	const frozenRightColumns = useMemo(
		() => columns.filter(column => column.frozen === 'end'),
		[columns]
	)

	const getCellValueCallback = useCallback(
		(column: GenericColumnModel) => {
			if (rowData) {
				// this row is from footerRowsData
				const formatterCell: FormatterCell = {
					value: rowData[column.id] || '',
					rowData,
					columnId: column.id
				}

				return cellFormatter(column, formatterCell, false);

			} else {
				// this row is generated from columns
				if (!column.footerValue) {
					return '';
				}

				if (column.footerFormatter) {
					const cell: FormatterCell = {
						value: column.footerValue(),
						rowData: [], // INFO: nije implementirano posto nije postojala potreba
						columnId: column.id
					}

					return column.footerFormatter(cell);
				}

				return column.footerValue();
			}
		},
		[rowData]
	)

	const cellsContent = useCallback(
		(newColumns: GenericColumnModel[]) => {
			return newColumns.map((column) => {
				return (
					<Cell
						key={column.id}
						column={column}
						columnWidth={columnWidthMap[column.id]}
						getCellValue={getCellValueCallback}
					/>
				)
			})
		},
		[columnWidthMap, getCellValueCallback]
	)

	return (
		<div className={styles.row_wrapper}>
			<div className={styles.row}>
				{columnsMemo.length > 0 && (
					frozenRightColumns.length > 0 ?
						<div className={styles.cells_wrapper}>
							{cellsContent(columnsMemo)}
						</div>
						:
						cellsContent(columnsMemo)
				)}
				{frozenRightColumns.length > 0 &&
					cellsContent(frozenRightColumns)
				}
			</div>
		</div>
	)
}

export const Row = memo(RowWithoutMemo);
