import { GenericColumnModel } from "../models"
import styles from './footer.module.scss'
import { Row } from "./Row"
import { ColumnWidthMap } from "../CustomTable"

type Props = {
	columns: GenericColumnModel[]
	columnWidthMap: ColumnWidthMap
	footerRowsData?: any[]
}

export const Footer = ({ columns, columnWidthMap, footerRowsData }: Props) => {
	const hasFooterInColumns = columns.some((column) => column.footerValue !== undefined);
	const hasFooterRowsData = footerRowsData && footerRowsData.length > 0;

	const hideFooter = !hasFooterInColumns && !hasFooterRowsData;
	if (hideFooter) {
		return <></>
	}

	return (
		<div className={styles.container}>
			{hasFooterInColumns &&
				<Row
					columns={columns}
					columnWidthMap={columnWidthMap}
				/>
			}
			{hasFooterRowsData &&
				footerRowsData.map((rowData) => (
					<Row
						rowData={rowData}
						columns={columns}
						columnWidthMap={columnWidthMap}
					/>
				))

			}
		</div>
	)
}
